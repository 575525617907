import airbrake from '../shared/airbrake'

import {
  captureFirstVisit,
  captureLastVisit,
  migrateKeys,
} from './capture-visit'

import {
  maybeSetValue,
  registerMultiFieldListener,
} from './formstack-facade'

import { getAnonymousId } from './customerio-facade'
import { push } from './gtm-facade'

const getEmailParam = () => {
  const params = new URLSearchParams(window.location.search)
  return params.get('email')
}

document.addEventListener('DOMContentLoaded', () => {
  const notifier = airbrake({
    projectId: 605320,
    projectKey: '5c0eac79df1c1a8ad86d4df539b9af56',
  })

  // NOTE: whenever an email is available in the search params, we assume this
  // indicates a conversion (approved by marketing)
  const email = getEmailParam()
  if (email) {
    push({ email, event: 'ec_form_submit' })
  }

  // TODO: remove this migration once we're confident it has been in production
  // long enough (a few weeks?)
  migrateKeys()

  const firstVisitUrl = captureFirstVisit()
  const lastVisitUrl = captureLastVisit()

  maybeSetValue('anonymous_id', getAnonymousId)
  maybeSetValue('first_visit_url', firstVisitUrl)
  maybeSetValue('last_visit_url', lastVisitUrl)

  registerMultiFieldListener(
    ['anonymous_id', 'first_visit_url', 'last_visit_url'],
    'submit',
    ({ fields, formId }) => {
      const unset = fields.filter(({ value }) => !value)
      if (unset.length === 0) return

      const error = new Error('Fields that should have been set were not')
      notifier.notify({
        error,
        context: { formId, unset, severity: 'warning' },
      })
    }
  )
})
